<template>
    <!-- FILTERS COLUMN -->
    <b-card class="addon">
        <!--  -->
        <div class="mx-50 d-flex justify-content-between align-items-center">
            <h3>
            {{ $t("media.filters.action.filter") }}
            </h3>
            <b-icon-filter
                v-if="classifiers.length > 0"
                variant="primary"
                class="d-lg-none"
                @click="toggleFilters"
            />
        </div>

        <!--  -->
        <div
            v-if="classifiers.length > 0"
            class="flex-column justify-content-center filters-container mb-2 px-50 pt-1"
            :class="areFiltersVisible ? 'd-flex' : 'd-none d-lg-flex'"
        >
            <div class="d-flex flex-column filters-container mb-2 px-50 pt-1">
            <!-- Classifiers -->
            <b-form-group
                :label="$t('filters.logic')"
                label-for="filterLogicLabel"
                class="w-100"
            >
                <v-select
                name="filterLogicLabel"
                v-model="filterLogic"
                :searchable=false
                :clearable="false"
                label="name"
                :reduce="(a) => a"
                return-object
                placeholder="Select..."
                :options="orAndOptions"
                disabled
                />
            </b-form-group>

            <!-- Filters -->
            <b-form-group
                v-for="classifierType in classifiers"
                :key="classifierType.key"
                :label="translateTranslationTable(currentLocale, classifierType.name)"
                :label-for="`classifier-filter-${classifierType.key}`"
                class="w-100"
            >
                <v-select
                    v-model="selectedClassifiersForFilter[classifierType.key]"
                    :name="`classifier-filter-${classifierType.key}`"
                    clearable
                    :searchable=false
                    label="name"
                    placeholder="Select..."
                    :reduce="(a) => a"
                    return-object
                    :options="filterOptions(classifierType)"
                    @input="setSelectedFinalFilter"
                />
            </b-form-group>

            <!-- End Custom -->
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="handleChanger(true)"
            >
                SEARCH
            </b-button>
            </div>
        </div>

        <!-- No filters available -->
        <b-row
            v-else
            class="horizontal-placeholder d-none d-lg-block h-100 media-placeholder"
        >
            <b-col cols="12">
            <img :src="placeholderFilter" />
            </b-col>
            <b-col cols="12">
            <p class="text-primary">
                {{ $t("available.message", { itemName: $t("media.filters.action.filter") }) }}
            </p>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import vSelect from "vue-select";
import FilterPlaceholder from "@/assets/images/placeholders/light/filters.svg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default defineComponent({
    name: 'OrganizationsFilter',
    components: {
        vSelect,
    },
    mixins: [],
    props: {
        classifiers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
            filterLogic: { name: this.$t("AND"), value: "AND" },
            areFiltersVisible: true,
            nextFilter: null,
            selectedClassifiersForFilter: [],
            finalSelectClassifier: [],
            selectedFilteringClassifiers: [],
            selectedCustomFieldsForFilter: null,
            customFieldToSend: {},
            showFilter: false,
        };
    },
    async created() {
        console.log('OrganizationsFilter Created!!')
        console.log('this.classifiers', this.classifiers);
        // this.isLoading = true;
        // // 
        // this.isLoading = false;
    },
    setup() {
        
    },
    computed: {
        placeholderFilter() {
            return FilterPlaceholder;
        },
        currentLocale() {
            return this.$store.state.locale.currentLocale;
        },
        orAndOptions() {
            //return ["OR", "AND"];
            return [
                { name: this.$t("AND"), value: "AND" },
                { name: this.$t("OR"), value: "OR" }
            ];
        },
    },
    methods: {
        filterOptions(classifierType) {
            let translatedClassifiers = [];
            for(let classifier of classifierType.classifiers){
                //console.log('classifier', classifier);
                translatedClassifiers.push({
                    // text: this.translateTranslationTable(this.currentLocale, classifier.name),
                    // value: classifier.key,
                    name: this.translateTranslationTable(this.currentLocale, classifier.name),
                    typeKey: classifierType.key,
                    key: classifier.key,
                    type: 1,
                });
            }
            return translatedClassifiers;
        },
        translateTranslationTable,
        toggleFilters() {
            this.areFiltersVisible = !this.areFiltersVisible;
            if (!this.areFiltersVisible) {
                this.customFieldToSend = {};
                this.finalSelectClassifier = [];
                this.selectedFilteringClassifiers = [];
                (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
                (this.selectedClassifiersForFilter = []);
            }
        },
        async setSelectedFinalFilter(selected) {
            // console.log('setSelectedFinalFilter selected', selected);
            // console.log('this.selectedClassifiersForFilter', this.selectedClassifiersForFilter);
            this.selectedFilteringClassifiers = [];
            for(let selectedClassifier of Object.values(this.selectedClassifiersForFilter)){
                // console.log('selectedClassifier', selectedClassifier);
                if(selectedClassifier?.key){
                this.selectedFilteringClassifiers.push(selectedClassifier.key);
                }
            }
            // console.log('this.selectedFilteringClassifiers', this.selectedFilteringClassifiers);
            //Lo vamos a hacer con un botón de buscar, así ahorramos queries innecesarias
            //this.handleChanger();
        },
    }
})
</script>

<style scoped>

</style>